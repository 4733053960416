import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout navClass="w_menu" headerClass="transparent_header">
    <SEO title="404: Not found" />
    <section className="breadcrumb_area">
      <img
        className="breadcrumb_shap"
        src={require("../images/breadcrumb/banner_bg.png")}
        alt=""
      />
      <div className="container">
        <div className="breadcrumb_content text-center">
          <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">
            404: Not Found
          </h1>
          <p className="f_400 w_color f_size_16 l_height26">
            This can happen if you mistyped a URL or we accidentally linked to a
            page that doesn't exist.
          </p>
        </div>
      </div>
    </section>
    <section className="container text-center my-5">
      <img
        src={require("../images/new/error.png")}
        className="img-fluid"
        alt="404"
      />

      <h2 className="my-3">
        Go <Link to="/">home &rsaquo;</Link>
      </h2>
    </section>
  </Layout>
)

export default NotFoundPage
